.toolbar::before {
    content: "";
    position: absolute;
    display: block;
    height: 24px;
    z-index: -1;
    top: -9px;
    left: -1px;
    right: -1px;
}

.toolbar-base-style.toolbar::before {
    background-color: rgba(var(--card-bg-opaque), 1);
}

.toolbar-alt-style.toolbar::before {
    filter: brightness(1.4);
    background-color: rgba(var(--nav-bg), 1);
    top: -16px;
}